<template>
  <article class="active-bet" :class="props.type" data-t="active-bet">
    <header class="header" :class="{ collapsed: !isShown }">
      <div class="info">
        <div class="icon-status" :style="{ color: statusIcon.color }">
          <StIcon :name="statusIcon.name" :size="16" />
        </div>
        <span class="bet-type">{{ betNameByType }}</span>
        <StCounter
          v-if="bet.outcomes.length > 1"
          bg-color="black"
          color="white"
          :label="bet.outcomes.length"
          size="xxs"
        />
      </div>
      <p class="created-at">{{ createdAt }}</p>
      <div class="actions">
        <StCopy :value="String(bet.id)">
          <span class="bet-id">{{ bet.id }}</span>
        </StCopy>

        <StIcon
          v-if="props.bet.type === 'express'"
          class="toggle"
          :name="isShown ? 'chevron-top' : 'chevron-down'"
          :size="16"
          @click="isShown = !isShown"
        />
      </div>
    </header>
    <StTransitionExpand>
      <div v-if="isShown" class="bet-body">
        <ActiveBetOutcome
          v-for="outcome in bet.outcomes"
          :key="outcome.id"
          :outcome="outcome"
          :type="props.type"
          :bet="bet"
        />
      </div>
    </StTransitionExpand>
    <footer class="bet-footer">
      <div class="footer-rows">
        <div v-if="props.bet.type === 'express'" class="footer-row">
          <span class="small">
            {{ t('bets.rate') }}
          </span>
          <p class="rate">
            {{ betRate }}
          </p>
        </div>
        <div class="footer-row">
          <span class="small">
            {{
              props.bet.accountType === 'real'
                ? t('bets.bid')
                : t('bets.freebet')
            }}
          </span>
          <div class="amount">
            <strong data-t="bidAmount">
              {{ format(Number(props.bet.amount)) }}
            </strong>
            <StIcon v-if="currencyIcon" :name="currencyIcon" :size="16" />
          </div>
        </div>
        <div class="footer-row">
          <span class="small">
            {{ resultText }}
          </span>
          <div class="amount result-amount">
            <strong data-t="resultAmount" :class="betWinLoseStatus">
              {{ resultAmount }}
            </strong>
            <StIcon v-if="currencyIcon" :name="currencyIcon" :size="16" />
          </div>
        </div>
      </div>
      <div
        v-if="isVisibleCashoutsButtons"
        class="cashout-buttons"
        data-t="cashouts-buttons"
      >
        <CashoutButtons
          :amount="cashoutAmountWithCurrency"
          :back-amount="cashoutBackendAmountWithCurrency"
          :cashout-info-status="cashoutInfoStatus"
          :cashout-status="cashoutStatus"
          full-width
          @start-cashout-process="startCashoutProcess"
          @cash-out-bet="cashOutBet"
        />
      </div>
    </footer>
  </article>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import CashoutButtons from '../CashoutButtons/CashoutButtons.vue'
import { useHistoryBet } from '../../composables/useHistoryBet'
import { useBetCashout } from '../../composables/useBetCashout'
import type { ExtendedBet } from '../../types'
import ActiveBetOutcome from './parts/ActiveBetOutcome.vue'

interface Props {
  bet: ExtendedBet
  type?: 'compact' | 'extended'
}

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), {
  type: 'compact',
})
const { bet } = toRefs(props)

const {
  currencyIcon,
  resultAmount,
  createdAt,
  betWinLoseStatus,
  resultText,
  statusIcon,
  betRate,
} = useHistoryBet(bet)
const { format } = useCryptoFormatter()

const betNameByType = computed(() =>
  props.bet.type === 'express' ? t('bets.express') : t('bets.ordinar'),
)
const isShown = ref(true)

const {
  betCashOutAmount,
  cashoutStatus,
  cashoutAmountWithCurrency,
  cashoutInfoStatus,
  cashoutBackendAmountWithCurrency,
  startCashoutProcess,
  cashOutBet,
} = useBetCashout(bet)

const isVisibleCashoutsButtons = computed(
  () =>
    new Decimal(betCashOutAmount.value).greaterThan(0) &&
    cashoutStatus.value !== 'success',
)
</script>

<style scoped>
.header {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  padding: var(--spacing-150) var(--spacing-150) var(--spacing-100)
    var(--spacing-150);

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);

  border-radius: var(--border-radius-100);
}

.info {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.created-at {
  margin: 0;
}

.actions {
  display: flex;
  gap: var(--spacing-125);
  align-items: center;
  margin-left: auto;
}

.bet-type {
  font: var(--desktop-text-xs-semibold);
  color: var(--text-primary);
}

.bet-footer {
  padding: var(--spacing-125) var(--spacing-150) var(--spacing-150)
    var(--spacing-150);
  background: var(--background-secondary);
  border-radius: 0 0 var(--border-radius-100) var(--border-radius-100);
}

.footer-rows {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
}

.footer-row {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;
}

.small {
  overflow: hidden;

  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rate {
  margin: 0;

  /* stylelint-disable */
  font-size: 14px;
  font-weight: 800;
  font-style: italic;
  line-height: 18px;
  color: var(--text-primary);
  letter-spacing: 0.28px;
  /* stylelint-enable */
}

.amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  strong {
    font: var(--desktop-text-sm-semibold);
    color: var(--text-primary);

    &.win,
    &.cashout {
      color: var(--system-success);
    }

    &.lose {
      color: var(--text-secondary);
    }
  }
}

.cashout-buttons {
  display: flex;
  margin-top: var(--spacing-150);
}

.toggle {
  cursor: pointer;

  &:hover {
    color: var(--text-primary);
  }
}

.bet-id {
  overflow: hidden;
  max-width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-status {
  display: flex;
  align-items: center;
}

.active-bet {
  margin-bottom: var(--spacing-150);
  background: var(--background-primary);
  border-radius: var(--border-radius-100);

  &.extended {
    background: var(--background-secondary);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16); /* stylelint-disable-line */

    .header {
      font: var(--mobile-caption-1-medium);
      transition: padding 0.2s;

      &.collapsed {
        padding: var(--spacing-150);
      }
    }

    .bet-footer {
      padding: var(--spacing-150);
      background: var(--background-tertiary);
    }

    .small {
      font: var(--mobile-text-medium);
      color: var(--text-tertiary);
    }

    .footer-rows {
      gap: var(--spacing-100);
    }

    .cashout-buttons {
      margin-top: 14px; /* stylelint-disable-line */
    }

    &:first-child {
      :deep(.outcome-separator) {
        &::before,
        &::after {
          /* stylelint-disable-next-line */
          background-color: #1a1a22;
        }
      }
    }
  }
}
</style>
